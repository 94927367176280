<template>
   <body>

      <!-- preloader -->
      <Loader> </Loader>
      <!-- preloader -->

      <div class="frame">
         <div class="site-wrapper overflow-hidden">

            <!-- Header Section -->
            <Front-Header> </Front-Header>

            <!-- Main End-->


            <section class="content-sec product-checkout offer-change-on-mobile">
               <div class="product-checkout-wrraper">
                  <div class="container holder">
                     <div class="row content-sec-area" v-if="item">

                        <div data-aos="fade-left" class="col-md-12 col-lg-6 order-1 left-side">
                           <div class="content-desc">
                              <h1>{{ item.name }}</h1>

                              <!--<div class="review-links">
                  <img data-src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-11068-5.png" alt="rating" style="padding-bottom: 10px;" class=" lazyloaded" title="TripAdvisor Traveler Rating 4.0 out of 5 Based on 23 Reviews" src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-11068-5.png">
                  <div class="review-col">
                     <span class="reviews">Based on <b>23</b> reviews</span>
                  </div>
                  <ul class="list-inline links">
                     <li><a id="trip-advisor-review" href="#read_review_widget" class="inline read_views  cboxElement" style=""> Read Reviews</a></li>
                  </ul>
               </div>--->

                              <div class="bottom-content">
                                 {{ item.about }}
                                 <div class="merchant-info row p-0">
                                    <div class="col-md-4 col-lg-5 d-flex align-items-start">
                                       <span class="icon" style="margin-right:10px;">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"
                                             viewBox="0 0 14 15">
                                             <path fill="#606D7A"
                                                d="M4.642 0L.49 1.768c-.295.126-.489.43-.489.768v11.583c0 .585.555.985 1.066.768l3.625-1.542L9.358 15l4.153-1.768c.295-.126.489-.43.489-.768V.881c0-.585-.555-.985-1.066-.768L9.309 1.655 4.642 0zm.802 2.028l3.112 1.104v9.84l-3.112-1.104v-9.84zm-1.555.074v9.802l-2.333.994V3.096l2.333-.994zm8.555 0v9.802l-2.333.994V3.096l2.333-.994z">
                                             </path>
                                          </svg>
                                       </span>
                                       <span class="text">
                                          {{ item.location }} </span>
                                    </div>
                                    <div class="col-md-4 col-lg-6 d-flex align-items-start">
                                       <span class="icon" style="margin-right:10px;">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16">
                                             <path fill="#606D7A"
                                                d="M8 0C3.591 0 0 3.591 0 8s3.591 8 8 8 8-3.591 8-8-3.591-8-8-8zm0 1.6c.199 0 .416.081.694.345.277.264.578.707.837 1.29.197.443.359.984.497 1.565H5.972c.138-.581.3-1.122.497-1.566.259-.582.56-1.025.837-1.289.278-.264.495-.345.694-.345zm-2.84.667c-.051.105-.105.208-.154.317-.29.651-.514 1.405-.683 2.216H2.458c.629-1.09 1.57-1.973 2.703-2.533zm5.68 0c1.132.56 2.073 1.443 2.702 2.533h-1.865c-.17-.811-.394-1.565-.683-2.216-.05-.11-.103-.212-.155-.317zM1.81 6.4h2.268C4.03 6.918 4 7.45 4 8s.029 1.082.078 1.6H1.81C1.678 9.088 1.6 8.554 1.6 8s.078-1.088.21-1.6zm3.884 0h4.612c.056.514.094 1.045.094 1.6 0 .555-.038 1.086-.094 1.6H5.694C5.638 9.086 5.6 8.555 5.6 8c0-.555.038-1.086.094-1.6zm6.228 0h2.269c.131.512.209 1.046.209 1.6s-.078 1.088-.21 1.6h-2.268C11.97 9.082 12 8.55 12 8s-.029-1.082-.078-1.6zm-9.464 4.8h1.865c.17.811.394 1.565.683 2.216.05.11.103.212.155.317-1.133-.56-2.074-1.443-2.703-2.533zm3.514 0h4.056c-.138.581-.3 1.122-.497 1.566-.259.582-.56 1.025-.837 1.289-.278.264-.495.345-.694.345-.199 0-.416-.081-.694-.345-.277-.264-.578-.707-.837-1.29-.197-.443-.359-.984-.497-1.565zm5.705 0h1.865c-.629 1.09-1.57 1.973-2.703 2.533.052-.105.106-.208.155-.317.29-.651.514-1.405.683-2.216z">
                                             </path>
                                          </svg>
                                       </span>
                                       <span class="text">
                                          <a target="_blank" v-bind:href="item.website">{{ item.website }}</a> </span>
                                    </div>
                                    <div class="col-md-4 col-lg-5 d-flex align-items-start">
                                       <span class="icon" style="margin-right:10px;">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15"
                                             viewBox="0 0 11 15">
                                             <path fill="#606D7A"
                                                d="M5.5 0C2.467 0 0 2.353 0 5.245c0 3.362 4.693 8.823 4.893 9.053L5.5 15l.607-.702c.2-.23 4.893-5.691 4.893-9.053C11 2.353 8.533 0 5.5 0zm0 1.5c2.165 0 3.927 1.68 3.927 3.745 0 2.029-2.45 5.518-3.927 7.366-1.477-1.847-3.927-5.334-3.927-7.366C1.573 3.181 3.335 1.5 5.5 1.5zm0 1.867c-1.086 0-1.966.84-1.966 1.875 0 1.036.88 1.875 1.966 1.875 1.086 0 1.966-.84 1.966-1.875 0-1.035-.88-1.875-1.966-1.875z">
                                             </path>
                                          </svg>
                                       </span>
                                       <span class="text">{{ asas }}</span>
                                    </div>
                                    <div class="col-md-4 col-lg-6 d-flex align-items-start">
                                       <span class="icon" style="margin-right:10px;">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16">
                                             <path fill="#606D7A"
                                                d="M15.132 11.1c-.56-.013-1.265-.046-1.71-.124-.482-.085-1.062-.25-1.47-.378-.32-.1-.668-.011-.906.225l-1.97 1.96C7.714 12.064 6.623 11.228 5.7 10.3c-.93-.923-1.765-2.014-2.482-3.376l1.959-1.971c.236-.237.324-.586.225-.906-.126-.407-.293-.987-.377-1.468-.08-.446-.111-1.15-.125-1.71C4.888.383 4.493 0 4.009 0H.889C.499 0 0 .292 0 .889c0 4.035 1.636 7.888 4.414 10.697C7.224 14.364 11.076 16 15.111 16c.597 0 .889-.5.889-.889v-3.12c0-.484-.384-.879-.868-.89z">
                                             </path>
                                          </svg>
                                       </span>
                                       <span class="text">{{ item.phone_no }} </span>
                                    </div>
                                 </div>
                              </div>
                              <dl class="info-list" style="display: none;">
                                 <div>
                                    <dt>Location</dt>
                                 </div>
                                 <dd>Al Safa Centre (Park-n-Shop)</dd>
                                 <dt>Area</dt>
                                 <dd>Al Safa</dd>
                                 <dt>Phone</dt>
                                 <dd class="number">+971 4 394 5616</dd>
                                 <dt>Website</dt>
                                 <dd><a target="_blank" href="http://www.pizzaexpress.ae">http://www.pizzaexpress.ae</a>
                                 </dd>
                              </dl>
                              <!--<a href="#" style="margin-top:30px;" class="btn btn-primary aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">Intro Insider</a>--->
                           </div>
                        </div>






                        <div data-aos="fade-right" class="col-md-12 col-lg-6 order-lg-1">

                           <div class="cover-img-wrap">
                              <div class="cover-img">
                                 <img alt="PizzaExpress Dubai" class=" lazyloaded main-img"
                                    data-src="https://offerengine.theentertainerme.com/pizzaexpress-dubai-x036526/merchant_profile_%22hero%22_image_slide.1_%28retina%29.jpg"
                                    :src="item.background_image">
                              </div>
                              <div class="merchant-logo">
                                 <img alt="PizzaExpress Dubai" class=" lazyloaded"
                                    data-src="https://offerengine.theentertainerme.com/pizzaexpress-x036526/merchant_primary_logo_%28retina%29_-_merchant.png"
                                    :src="item.image">
                              </div>
                           </div>

                        </div>


                     </div>
                  </div>
               </div>

            </section>



            <section class="offer-details-section">
               <div class="container">
                  <div class="row">
                     <div class="col-md-6">
                        <div id="details" v-if="item">
                           <div class="head">
                              <img :src="item.image">
                              <h3 class="title">
                                 {{ item.title }}
                              </h3>
                              <p class="disc">
                                 of equal value or lesser value
                              </p>
                           </div>





                           <div class="inner">
                              <ul>
                                 <li>
                                    <img :src="item.offer_image">
                                    <!-- <p>{{ feature }}</p> -->
                                 </li>

                              </ul>
                           </div>




                           <div class="bottom">
                              <p>
                                 Your esmated savings
                              </p>
                              <h3>
                                 {{ this.item.currency }} {{ item.amount }}
                              </h3>
                              <div v-if="countsforlogin == 56">
                                 <p> You need to login for redeem offer </p>
                                 <router-link :to="{ path: '/signin' }" class="btn btn-primary">Redeem </router-link>
                              </div>
                              <div v-else>
                                 <div class="wrapper" v-if="counts < 1024">
                                    <div
                                       v-if="item.paid_status == true && item.offer_payment_type == 1 && offerPaid == true && redeemOffer != true">
                                       <p>
                                          <!--  you need to buy the mobile product to use this offer -->
                                          Enter Company Code
                                       </p>

                                       <form class="form-horizontal form-material" v-on:submit.prevent="submit">
                                          <input type="text" v-model.trim="code" class="form-control form-control-lg">
                                          <!-- <div v-if="!$v.code.required" class="invalid-feedback">The code field is
                                          required.</div> -->
                                          <br>
                                          <button class="btn btn-primary">Redeem</button>
                                       </form>
                                    </div>
                                    <div
                                       v-else-if="item.paid_status == true && item.offer_payment_type == 1 && redeemOffer != true">
                                       <!-- <p>
                                          Enter Company Code
                                       </p>

                                       <form class="form-horizontal form-material" v-on:submit.prevent="submit">
                                          <input type="text" v-model.trim="code" class="form-control form-control-lg">
                                          <br>
                                          <button class="btn btn-primary">Redeem</button>
                                       </form> -->
                                       <p> This is paid offer you need to purchase for redeem the offer </p>
                                       <h6> Offer Amount </h6>
                                       <h3>
                                          {{ this.item.currency }} {{ item.offer_amount }}
                                       </h3>
                                       <button class="btn btn-primary" @click="purchaseOffer">Purchase</button>
                                    </div>

                                    <div class="wrapper" v-else-if="redeemOffer == true">
                                       <p> This is offer you Redeemed Already </p>
                                       <h6> Offer Amount </h6>
                                       <h3>
                                          {{ this.item.currency }} {{ item.offer_amount }}
                                       </h3>
                                       <label class="btn btn-secondary">Redeemed Already</label>
                                       <!-- <router-link :to="{ path: '/offer-detail/' + item.offer_id }" class="btn btn-primary"
                                       :disabled="true">Redeem </router-link> -->
                                    </div>

                                    <div v-else>
                                       <p>
                                          <!--  you need to buy the mobile product to use this offer -->
                                          Enter Company Code
                                       </p>

                                       <form class="form-horizontal form-material" v-on:submit.prevent="submit">
                                          <input type="text" v-model.trim="code" class="form-control form-control-lg">
                                          <!-- <div v-if="!$v.code.required" class="invalid-feedback">The code field is
                                          required.</div> -->
                                          <br>
                                          <button class="btn btn-primary">Redeem</button>
                                       </form>
                                    </div>
                                 </div>

                                 <div class="wrapper"
                                    v-else-if="item.paid_status == true && item.offer_payment_type == 1 && offerPaid == true">
                                    <p> You need mobile to redeem the offer </p>
                                    <router-link :to="{ path: '/offer-detail/' + item.offer_id }" class="btn btn-primary"
                                       :disabled="true">Redeem </router-link>
                                 </div>

                                 <div class="wrapper" v-else-if="item.paid_status == true && item.offer_payment_type == 1">
                                    <p> This is paid offer you need to purchase for redeem the offer </p>
                                    <h6> Offer Amount </h6>
                                    <h3>
                                       {{ this.item.currency }} {{ item.offer_amount }}
                                    </h3>
                                    <button class="btn btn-primary" @click="purchaseOffer">Purchase</button>
                                    <!-- <router-link :to="{ path: '/offer-detail/' + item.offer_id }" class="btn btn-primary"
                                       :disabled="true">Redeem </router-link> -->
                                 </div>

                                 <div class="wrapper" v-else-if="redeemOffer == true">
                                    <p> This is offer you Redeemed Already </p>
                                    <h6> Offer Amount </h6>
                                    <h3>
                                       {{ this.item.currency }} {{ item.offer_amount }}
                                    </h3>
                                    <label class="btn btn-primary">Redeemed Already</label>
                                    <!-- <router-link :to="{ path: '/offer-detail/' + item.offer_id }" class="btn btn-primary"
                                       :disabled="true">Redeem </router-link> -->
                                 </div>

                                 <div class="wrapper" v-else>
                                    <p> You need mobile to redeem the offer </p>
                                    <router-link :to="{ path: '/offer-detail/' + item.offer_id }" class="btn btn-primary"
                                       :disabled="true">Redeem </router-link>
                                 </div>
                              </div>
                           </div>



                        </div>
                     </div>
                     <div class="col-md-6">
                        <img src="../../assets/front/image/offer-details-bg.jpg">
                     </div>

                  </div>
               </div>

            </section>

            <section class="payment-section" id="payment-section" v-if="this.offerPurchase == true">
               <div class="payment">
                  <div class="payment-customer">
                     <h2>Ivan Yew</h2>
                  </div>
                  <div class="payment-method">
                     <h2 class="payment-method-text">Choose your payment method</h2>
                     <!-- <h2 class="payment-method-text">Offer Amount {{this.item.currency}} {{ Math.round(item.offer_amount) }}</h2> -->
                     <div class="pm-item">
                        <input id="mpp" type="radio" name="payment-method" checked="checked" />
                        <label class="pm-label" for="mpp">
                           <div class="pm-text">
                              <h5>Stripe</h5>
                              <p>
                                 Safe payment online. Credit card needed. Paypal account is
                                 not necessary.
                              </p>
                           </div>
                           <div class="pm-thumb">
                              <img src="../../assets/front/image/Stripe_logo.png" />
                           </div>
                        </label>
                     </div>
                  </div>

                  <div class="payment-input">
                     <div class="form-group rightColumn">
                     </div>
                  </div>

                  <div class="stripe" v-if="userExist">
                     <stripe-element-card ref="elementRef" :pk="pulishableKey" locale="en" :amount="item.offer_amount"
                        @token="tokenCreated" @loading="loading = $event" />
                     <div class="payment-btn-wrapper">
                        <button class="btn btn-primary" @click="Offersubmit">
                           Pay {{ Math.round(item.offer_amount) }}
                        </button>

                        <div :class="{ 'is-loading': isLoading }"></div>
                     </div>
                  </div>

                  <div class="stripe" v-else>
                     <router-link to="/signin" class="btn btn-primary btn-pay-now">Login</router-link>
                  </div>
               </div>
            </section>

            <!-- Main End-->

            <!-- Footer Section -->

            <Front-Footer> </Front-Footer>

         </div>
      </div>

   </body>
</template>
<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import axios from "axios";
import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { db } from '@/main'


export default {
   components: {
      'Front-Header': Header,
      'Front-Footer': Footer,
      'Loader': Loader,
      Loader: Loader,
      StripeElementCard
   },
   data() {
          this.pulishableKey = this.$pulishableKey;
      // this.pulishableKey =
      // "pk_test_51PXfYBKgKdpywnn7VfvgjcN3FJLXU6ongyx0O5g2taj5edHuyMhVGK5UuP5C6uzT8k4duBo7ynERXO3jtkR68gTc00kgtBkNYD";
         // "pk_test_51ISnc5C9aOZLHBgbfoJjTWKpxeMhIdW4O9g9huGkA6sFyQUNXJYZppuyssLdvHdinkZaRT3JITBWvH483poTpY3600iUtbPcPt";
      // "pk_test_51Jn0GYSCn9RmGB5DvmMHOu36CbYJFRpRbPpCGRmqUjLsK7DTBiOHdwQw7n1nzUBo4ds9sV1D1TTkmgfIWIjaoCsX0006wPGnYU";
      return {
         users: {},
         loading: false,
         isLoading: false,
         offer_amount: 0,
         company_sharing_amount: 0,
         stripe_connect_id: '',
         currency: '',
         offer_code: '',
         userid: '',
         useremail: "",
         adminemail: "",
         // currency: "INR",
         token: null,
         charge: null,
         userExist: null,
         triggerDelete: 0,
         item: '',
         code: '',
         counts: '',
         emailu: '',
         meta_title: '',
         meta_description: '',
         items: '',
         date: '', dd: '',
         asas: '',
         countsforlogin: '',
         mm: '', yyyy: '',
         today: '',
         adminemail: '', firstname: '',
         userExist: null,
         offerPurchase: false,
         offerPaid: false,
         purchaseOfferData: [],
         redeemOffer: false,
      }
   },
   metaInfo() {
      //alert();
      return {
         title: this.meta_title,
         meta: [
            { name: 'description', content: this.meta_description },
         ]
      }
   },

   created: function () {
      this.fetchItems();
      this.getOfferPurchase();
      this.getRedeemOffer();
   },

   methods: {

      async submit() {
         // alert(this.item.id)
         // alert(parseInt(this.code))
         this.query = db.collection("offers")
            .where("company", "==", this.item.id)
            .where("offer_code", "==", this.code);
         const snapshot = await this.query.get();
         this.count = snapshot.size;
         // alert(this.count);
         if (this.count != 0) {
    


            if (this.item.yyyyy >= this.yyyy && this.item.mmm >= this.mm && this.item.ddd <= this.dd || this.item.ddd >= this.dd) {
               var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
               var uid = localStorage.getItem("userSessionId");
               var offer_id = this.$route.params.id;
           

               this.query1 = db.collection("redeem_offers")
                  .where("uid", "==", uid)
                  .where("offer_id", "==", offer_id);

               const snapshotu = await this.query1.get();
               this.count = snapshotu.size;
         
               if (this.count == 0) {
                  db.collection("redeem_offers")
                     .add({
                        uid: uid,
                        amount: this.item.amount,
                        offer_id: this.item.offer_id,
                        created: date,
                        modified: date,
                     }).then(() => {
                        console.log("Added Successfully!");
                        this.showNotification('Success, Offer Redeemed Successfully!', 'AlertTriangleIcon', 'success')	   /*axios*/
                        var axios = require('axios');
                        var FormData = require('form-data');
                        var data = new FormData();
                        data.append('user_email', this.emailu);
                        data.append('comapny_email', this.item.email);
                        data.append('admin_email', this.adminemail);
                        data.append('offer_expire', this.item.valid_date);
                        data.append('offer_title', this.item.title);
                        data.append('company', this.item.company);
                        data.append('firstname', this.firstname);


                        var config = {
                           // this.$VUE_APP_API_ENDPOINT + "purchase-offer-transfer";
                           method: 'post', url: this.$VUE_APP_API_ENDPOINT + 'email_for_redeem_offer', headers: {
                              'Content-Type': 'application/json'
                           }, data: data
                        };

                        axios(config)
                           .then(function (response) {
                              console.log(JSON.stringify(response.data));
                              this.fetchItems();
                              this.getOfferPurchase();
                              this.getRedeemOffer();
                           })
                           .catch(function (error) {
                              console.log(error);
                           });	   /*End of the axios*/

                        this.fetchItems();
                        this.getOfferPurchase();
                        this.getRedeemOffer();

                     })
                     .catch((error) => {
                        console.error("Error writing document: ", error);
                     });
               } else {
                  this.showNotification('Error,You Already Redrem Offer!', 'AlertTriangleIcon', 'danger')
               }
               // }

            } else {
               this.showNotification('Error, Offer Expired!', 'AlertTriangleIcon', 'danger')

            }

         } else {
            this.showNotification('Error, Wrong Offer code!', 'AlertTriangleIcon', 'danger')

         }
         //alert(this.count);

      },

      purchaseOffer() {
         // alert()
         this.offerPurchase = true;
         document.getElementById('payment-section').scrollIntoView({
            behavior: "smooth"
         });

      },

      getRedeemOffer() {
         // alert();
         var uid = localStorage.getItem("userSessionId");
         var id = this.$route.params.id;
         db.collection("redeem_offers")
            .where("uid", "==", uid)
            .where("offer_id", "==", id)
            .get()
            .then((querySnapshot) => {
               querySnapshot.forEach((doc) => {
                  console.log(doc.data())
                  this.redeemOffer = true;
                  // alert(this.redeemOffer);
                  // this.offerPaid = doc.data().offerPaid;
               });
            })
            .catch((error) => {
               console.log("Error getting documents: ", error);
            });
      },

      getOfferPurchase() {
         // alert();
         var uid = localStorage.getItem("userSessionId");
         var id = this.$route.params.id;
         db.collection("offer_purchase")
            .where("userid", "==", uid)
            .where("offer_id", "==", id)
            .get()
            .then((querySnapshot) => {
               querySnapshot.forEach((doc) => {
                  console.log(doc.data())
                  this.offerPaid = doc.data().offerPaid;
               });
            })
            .catch((error) => {
               console.log("Error getting documents: ", error);
            });
      },

      fetchItems() {
         var uid = localStorage.getItem("userSessionId");
         this.userExist = uid;
         window.scrollTo({ top: 0, behavior: 'smooth' });
         var id = this.$route.params.id;

         var uid = localStorage.getItem("userSessionId");
         // var emailu = localStorage.getItem("email");
         db.collection("users")
            .doc(uid)
            .get()
            .then((doc) => {
               // console.log('hhhh99');
               //console.log(doc.id, " => ", doc.data());
               this.emailu = doc.data().email;
            })
            .catch((error) => {
               console.log("Error getting documents: ", error);
            });

         db.collection("offers").doc(id)
            .get()
            .then((doc) => {
               db.collection('companies').doc(doc.data().company).get().then(async (res) => {
                  db.collection('countries').doc(res.data().country)
                     .get().then(snapshot => {
                        if (!snapshot.exists) return; this.asas = snapshot.data().country;
                     });
                  console.log(res.data());

                  this.item = res.data();
                  this.item.id = res.id;
                  this.item.logo = res.data().image;
                  this.item.company = res.data().name;
                  this.item.subscription = res.data().subscription;
                  this.item.valid_date = doc.data().valid_date;
                  this.item.splitdate = this.item.valid_date.split("-");
                  this.item.yyyyy = this.item.splitdate[0];
                  this.item.mmm = this.item.splitdate[1];
                  this.item.ddd = this.item.splitdate[2];
                  this.item.offer_id = doc.id;
                  this.item.title = doc.data().title;
                  this.item.email = res.data().email;
                  this.item.offer_image = doc.data().image;
                  this.item.is_reuseable = doc.data().is_reuseable;
                  // this.item.stripe_connect_id = 'acct_1PXi8XIvavv4GhUt';
                  this.item.stripe_connect_id = res.data()?.stripe_connect_id || "default_value";
                  this.item.amount = doc.data().amount;
                  this.item.feature = doc.data().feature;
                  this.item.paid_status = doc.data().paid_status;
                  this.item.offer_payment_type = doc.data().offer_payment_type;
                  this.item.offer_amount = doc.data().offer_amount;
                  this.item.company_sharing_amount = doc.data().company_sharing;
                  this.item.company_sharing = doc.data().company_sharing;
                  this.item.currency = doc.data().currency;
                  this.item.offer_code = doc.data().offer_code;


                  console.log(doc.data());
                  console.log(res.data().email);
                  console.log("this.item.email");
                  this.meta_title = doc.data().meta_title;
                  this.meta_description = doc.data().meta_description;
                  // localStorage.setItem("meta_title",doc.data().meta_title);
                  //localStorage.setItem("meta_description", doc.data().meta_description);
                  var uid = localStorage.getItem("userSessionId");

                  if (uid != null) {

                     // this.query =  db.collection("order_details").where("uid", "==", uid).where("subscription", "==", res.data().subscription);
                     // const snapshot =  await this.query.get();
                     // this.counts  = snapshot.size;
                     // console.log(this.counts);
                     //  console.log("this.counts");
                     //console.log(this.count);
                     this.counts = screen.width;
                     console.log(this.counts);

                  } else { this.countsforlogin = 56; }


               });



            })
            .catch((error) => {
               console.log("Error getting documents: ", error);
            });

         /* get admin user */
         db.collection("users")
            .where("status", "==", 1)
            .get()
            .then((querySnapshot) => {
               querySnapshot.forEach((doc) => {
                  this.userid = doc.id;
                  this.adminemail = doc.data().email;
                  this.firstname = doc.data().firstname;
                  // console.log(doc.data().email);

               })
            }).catch((error) => {
               console.log("Error getting documents: ", error);
            });
         /*End get admin user */
         /* get current date and time */

         this.today = new Date();
         this.dd = String(this.today.getDate()).padStart(2, '0');
         this.mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
         this.yyyy = this.today.getFullYear();

         //console.log(this.mm + '/' + this.dd + '/' + this.yyyy);
         /*End of the curreent date */

      },

      Offersubmit() {
         this.$refs.elementRef.submit();
         // this.$refs.myChild.getCartCount();
      },

      tokenCreated(token) {
         this.token = token;
         this.isLoading = true;
         const intro_transfer_amount = (this.item.offer_amount / 100 * this.item.company_sharing_amount);
         console.log(intro_transfer_amount);
         // console.log(Math.round(intro_transfer_amount * 100));
         // alert()
         var date = new Date().toLocaleString();
         var offer_id = this.$route.params.id;
         var uid = localStorage.getItem("userSessionId");
         // alert(offer_id)
         db.collection('users').doc(uid).get().then((doc) => {
            console.log(doc.data());
            this.users = doc.data();

            const data = {
               card: this.token.id,
               useremail: doc.data().email,
               firstname: doc.data().firstname,
               lastname: doc.data().lastname,
               userid: doc.id,
               offer_id: offer_id,
               company_id: this.item.id,
               currency: this.item.currency,
               amount: Math.round(this.item.offer_amount * 100),
               company_sharing_amount: Math.round(this.item.company_sharing_amount * 100),
               stripe_connect_id: this.item.stripe_connect_id??'hell',
               description: "Intro Company Offer",
            };

            console.log(data);

            const url = this.$VUE_APP_API_ENDPOINT + "purchase-offer-transfer";
            // const url = this.$VUE_APP_API_ENDPOINT + "purchase-offer-transfer";

            const options = {
               method: 'POST',
               headers: { 'content-type': 'application/json' },
               data: JSON.stringify(data),
               url: url
            };

            function makeid(length) {
               var result = [];
               var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
               var charactersLength = characters.length;
               for (var i = 0; i < length; i++) {
                  result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
               }
               return result.join('');
            }


            axios(options).then(
               (response) => {
                  console.log(response);
                  // console.warn(Object.keys(response));
                  if (response.data.message) {
                     this.isLoading = false;
                     this.showNotification(response.data.message, 'AlertTriangleIcon', 'danger');
                  }
                  else {
                     if (this.item.stripe_connect_id == null) {
                        this.item.stripe_connect_id = null;
                     }
                     // alert(this.item.stripe_connect_id)
                     db.collection("offer_purchase")
                        .add({
                           card: this.token.id,
                           useremail: doc.data().email,
                           firstname: doc.data().firstname,
                           lastname: doc.data().lastname,
                           userid: doc.id,
                           offer_id: offer_id,
                           company_id: this.item.id,
                           currency: this.item.currency,
                           amount: Math.round(this.item.offer_amount * 100),
                           company_sharing_amount: Math.round(this.item.company_sharing_amount * 100),
                           stripe_connect_id: this.item.stripe_connect_id,
                           description: "Intro Company Offer",
                           offerPaid: true,
                           created: date,
                           modified: date,
                        }).then(() => {
                           this.isLoading = false;
                           console.log("Added Successfully!");
                           this.showNotification("Success, Paid Successfully!", 'AlertTriangleIcon', 'success');
                             setTimeout(() => {
      window.location.reload();
    }, 2000); 
                           // this.$router.go();
                           this.fetchItems();
                           this.getOfferPurchase();
                           this.getRedeemOffer();

                           console.log(doc.data());

                           const data = {
                              useremail: doc.data().email,
                              firstname: doc.data().firstname,
                              lastname: doc.data().lastname,
                              userid: doc.id,
                              offer_id: offer_id,
                              company_id: this.item.id,
                              offer_code: this.item.offer_code,
                           };
                           var config = {
                              // this.$VUE_APP_API_ENDPOINT + "purchase-offer-transfer";
                              method: 'post', url: this.$VUE_APP_API_ENDPOINT + 'email_for_purchase_offer', headers: {
                                 'Content-Type': 'application/json'
                              }, data: data
                           };

                           axios(config)
                              .then(function (response) {
                                 console.log(JSON.stringify(response.data));
                              })
                              .catch(function (error) {
                                 console.log(error);
                              });
                        })
                 
                  }
               }, () => {
                  this.isLoading = false;
                  this.showNotification('Error,Payment Faild!', 'AlertTriangleIcon', 'danger');
               });

         })


      },

      showNotification(title, icon, variant) {
         this.$toast({
            component: ToastificationContent,
            props: {
               title,
               icon,
               variant,
            },
         })
      },

   }
}
</script>
<style>
.is-loading {
   border: 16px solid #f3f3f3;
   border-radius: 50%;
   border-top: 16px solid #03e1bc;
   border-bottom: 16px solid #03e1bc;
   width: 20px;
   height: 20px;
   -webkit-animation: spin 2s linear infinite;
   animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
   }

   100% {
      -webkit-transform: rotate(360deg);
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

.payment-btn-wrapper {
   display: flex;
   align-items: center;
   padding-top: 20px;
   justify-content: center;
}

.payment-btn-wrapper button.btn.btn-primary {
   margin: 0;
   margin-right: 20px;
}

.payment-method-text {
   text-align: center;
}
</style>




